<template>
	<div id="business">
		<div class="main-page-wrapper font-gordita">
			<!-- ===================================================
				Loading Transition
			==================================================== -->
			<section>
				<div id="preloader">
					<div id="ctn-preloader" class="ctn-preloader">
						<div class="animation-preloader">
							<div class="text-center">
								<img class="spinner" src="../../../assets/image/ingomu-preloader.gif" alt="" />
							</div>
							<!-- <div class="spinner"></div> -->
							<div class="txt-loading">
								<span data-text-preloader="i" class="letters-loading">
									i
								</span>
								<span data-text-preloader="n" class="letters-loading">
									n
								</span>
								<span data-text-preloader="g" class="letters-loading-green">
									g
								</span>
								<span data-text-preloader="o" class="letters-loading-green">
									o
								</span>
								<span data-text-preloader="m" class="letters-loading">
									m
								</span>
								<span data-text-preloader="u" class="letters-loading">
									u
								</span>
							</div>
						</div>
					</div>
				</div>
			</section>


			<!-- 
			=============================================
				Theme Main Menu
			============================================== 
			-->
			<SiteHeader></SiteHeader>

			<!-- 
			=============================================
				Fancy Feature Twenty Five
			============================================== 
			-->
			<div class="fancy-feature-twentyFive lg-container mt-100 md-mt-50" id="about">
				<div class="container">
					<div class="block-style-twentyFive">
						<div class="row align-items-center text-center">
							<!-- <div class="col-xl-7 col-lg-6 col-md-10 m-auto" data-aos="fade-right" data-aos-duration="1200">
								<div class="screen-container">
									<img src="https://dktoyr513tjgs.cloudfront.net/v2/images/enterprise/hand_phone_logo_v1.png"
										alt="">
								</div> 
							</div> -->
							<div class="col-xl-12 col-lg-12" data-aos="fade-left" data-aos-duration="1200">
								<div class="text-wrapper pl-xl-5">
									<h4 class="title fs-50 fw-600">Get help with your strategy to attract, develop, and retain talent.</h4>
									<p>We help busy HR teams implement affordable coaching with a proven solution that offers an average 65% increase in self-reported well-being.</p>
								</div> <!-- /.text-wrapper -->
							</div>
						</div>
					</div> <!-- /.block-style-twentyFive -->

				</div>
			</div> <!-- /.fancy-feature-twentyFive -->


			<div class="container mt-38 md-mt-50">
				<div class="row">
					<div class="col-lg-7 col-md-9 m-auto">
						<div class="m-auto text-center">
        <a href="#" data-toggle="modal" data-target="#contactModal" class="letstalkbutton bluebutton demo-button color-white"><span>LET'S TALK</span></a>
      </div>
					</div>
				</div>
			</div>

			<!--
			=====================================================
				Client Feedback Slider Two
			=====================================================
			-->
			<div class="client-feedback-slider-two md-mt-90 bg-white pb-150 md-pt-10 md-pb-100">
	
				<div class="slider-content">
					<div class="clientSliderTwo">
						<div class="item" v-for="testimony in testimonialsList" :key="testimony.name">
							<div class="bg-wrapper bg-lblue">
								<!-- <p class="fs-18" v-html="testimony.title"></p> -->
								<div class="name font-gordita fs-16">{{ testimony.name }}</div>
								<div class="desig fs-16 text-dark" v-html="testimony.title"></div>
							</div> <!-- /.bg-wrapper -->
						</div>
					</div>
				</div> <!-- /.slider-content -->
			</div> <!-- /.client-feedback-slider-two -->

									<!-- 
			=============================================
				Fancy Text Block Nineteen
			============================================== 
			-->
			<div class="fancy-text-block-nineteen mb-150">
				<div class="container">
					<div class="row align-items-center" id="getstarted">
						<div class="col-lg-6" data-aos="fade-right" data-aos-duration="1200">
							<img src="https://dktoyr513tjgs.cloudfront.net/v2/images/assets/Empower-your-employees-with-Ingomu-(@Ingomu-@career-coaching).png" alt="" class="m-auto">
						</div>
						<div class="col-lg-6" data-aos="fade-left" data-aos-duration="1200">
							<div class="text-wrapper">
								<div class="client-info font-gordita text-dark fs-20">ABOUT</div>
								<div class="title-style-five">
									<h2 class="fs-50 fw-600 font-gordita">Unlock employee potential: personalized coaching at their fingertips for less than $1 a day!</h2>
								</div>
								<p class="font-gordita fs-20 font-gordita">Ingomu simplifies coaching with a curated selection of coaches via an easy to use app, eliminating search overwhelm and long wait times for one-on-one sessions.</p>
								<p class="font-gordita fs-20 font-gordita">Empower your employees with all access holistic support in leadership, burnout, self-care, and financial wellness. Elevate their well-being, reduce stress, enhance physical health, and boost mental resilience through live, virtual group coaching sessions and on-demand videos—accessible right at their fingertips in the Ingomu app, 24/7.</p>
								<p class="font-gordita fs-20 font-gordita">Ingomu provides an unparalleled value in personal coaching.</p>
							</div> <!-- /.text-wrapper -->
						</div>
					</div>
				</div>
			</div> <!-- /.fancy-text-block-nineteen -->

			
									<!--
			=====================================================
				Fancy Feature Nine
			=====================================================
			-->
			<div class="fancy-feature-nine">
				<img src="../../../assets/v2/images/shape/107.svg" alt="" class="shapes shape-one">
				<img src="../../../assets/v2/images/shape/108.svg" alt="" class="shapes shape-two">
				<img src="../../../assets/v2/images/shape/109.svg" alt="" class="shapes shape-three">
				<img src="../../../assets/v2/images/shape/110.svg" alt="" class="shapes shape-four">
				<img src="../../../assets/v2/images/shape/111.svg" alt="" class="shapes shape-five">
				<img src="../../../assets/v2/images/shape/112.svg" alt="" class="shapes shape-six">
				<div class="container" id="getdays">
					<div class="title-style-five text-center mb-60 md-mb-30">
						<h6 class="text-dark">GET STARTED IN DAYS</h6>
						<h2 class="fs-50 fw-600 font-gordita"><span>Implement 12X faster and see results quicker with our guided onboarding and implementation process.</span></h2>
					</div>

					<div class="row justify-content-center">
						<div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-duration="1200">
							<div class="block-style-fourteen">
								<div class="illustration"><img src="https://dktoyr513tjgs.cloudfront.net/v2/images/assets/Ingomu-getting-started-onboard-(@ingomu-@career-coaching).svg" alt=""></div>
								<div class="title text-dark fw-bold">ONBOARD</div>
								<p class="font-gordita fs-20">Assess the needs and establish benchmarks during a strategy session with our success team.</p>
							</div> <!-- /.block-style-fourteen -->
						</div>
						<div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
							<div class="block-style-fourteen arrow-shape">
								<div class="illustration"><img src="https://dktoyr513tjgs.cloudfront.net/v2/images/assets/Ingomu-getting-started-enroll-(@ingomu-@career-coaching).svg" alt=""></div>
								<div class="title text-dark fw-bold">ENROLL</div>
								<p class="font-gordita fs-20">Promote the coaching program, onboard your employees, and encourage use with resources we offer.</p>
							</div> <!-- /.block-style-fourteen -->
						</div>
						<div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200">
							<div class="block-style-fourteen">
								<div class="illustration"><img src="https://dktoyr513tjgs.cloudfront.net/v2/images/assets/Ingomu-getting-started-celebrate-(@ingomu-@career-coaching).svg" alt=""></div>
								<div class="title text-dark fw-bold">EVALUATE AND CELEBRATE</div>
								<p class="font-gordita fs-20">Receive monthly outcomes, assess their impact, and acknowledge achievements.</p>
							</div> <!-- /.block-style-fourteen -->
						</div>
					</div> <!-- /.row -->
				</div>
			</div> <!-- /.fancy-feature-nine -->


			<!--
			=====================================================
				Fancy Feature Three
			=====================================================
			-->
			<div class="fancy-feature-three pt-150 mt-100 md-mt-80 md-pt-20">
				<div class="container">
					<div class="row">
						<div class="col-lg-6">
							<div class="title-style-one">
								<h6 class="font-gordita text-dark">COACHING SHOWS YOU CARE</h6>
								<h2 class="fs-50 fw-600">Employees stay with companies who care.</h2>
							</div> <!-- /.title-style-one -->

							<div class="counter-info-classic">
								<div class="counter-box-one color-one">
									<h2 class="number font-gordita"><span class="timer" data-from="0" data-to="50"
											data-speed="1200" data-refresh-interval="5">0</span>+</h2>
									<p class="font-gordita">live sessions<br> per month</p>
								</div> <!-- /.counter-box-one -->
								<div class="counter-box-one color-two">
									<h2 class="number font-gordita fs30">Dozens</h2>
									<p class="font-gordita">of coaches</p>
								</div> <!-- /.counter-box-one -->
								<div class="counter-box-one color-three">
									<h2 class="number font-gordita fs-35">Hundreds</h2>
									<p class="font-gordita">of on-demand sessions</p>
								</div> <!-- /.counter-box-one -->
								<div class="bubble-one"></div>
								<div class="bubble-two"></div>
								<div class="bubble-three"></div>
								<div class="bubble-four"></div>
								<div class="bubble-five"></div>
								<div class="bubble-six"></div>
								<div class="bubble-seven"></div>
								<img src="../../../assets/v2/images/shape/8.svg" alt="" class="cs-screen dot-shape">
							</div> <!-- /.counter-info-classic -->
						</div>

						<div class="col-lg-6">
							<div class="clients-feedback-classic md-mt-80">
								<div class="feedback-wrapper p40" data-aos="fade-up" data-aos-duration="1200">
									<img data-name="Ingomu-icon-(@Ingomu)" src="https://dktoyr513tjgs.cloudfront.net/v2/images/enterprise/Ingomu-icon-(@Ingomu).png" alt=""
										class="media-meta">
									<p class="text-dark fs-20">94% of employees would stay with a company longer if that company invests in their career development and personal well-being.</p>
									<!-- <h6 class="name">Institute of Coaching</h6> -->
									<!-- <span class="font-rubik disg-info">Institute of Coaching</span> -->
								</div> <!-- /.feedback-wrapper -->
								<div class="feedback-wrapper p40" data-aos="fade-up" data-aos-duration="1200">
									<img data-name="Ingomu-icon-(@Ingomu)" src="https://dktoyr513tjgs.cloudfront.net/v2/images/enterprise/Ingomu-icon-(@Ingomu).png" alt=""
										class="media-meta">
									<p class="text-dark fs-20">86% of companies confirm that coaching delivers a measurable positive return on
										investment as reported by the International Coaching Federation.</p>
									<!-- <h6 class="name">Rashed Ka.</h6> -->
									<!-- <span class="font-rubik disg-info">Human Capital Institute</span> -->
								</div> <!-- /.feedback-wrapper -->
								<div class="feedback-wrapper p40" data-aos="fade-up" data-aos-duration="1200">
									<img data-name="Ingomu-icon-(@Ingomu)" src="https://dktoyr513tjgs.cloudfront.net/v2/images/enterprise/Ingomu-icon-(@Ingomu).png" alt=""
										class="media-meta">
									<p class="text-dark fs-20">51% of companies with a strong coaching culture enjoy higher revenues according to
										the Human Capital Institute.</p>
									<!-- <h6 class="name">Zubayer Hasan</h6> -->
									<!-- <span class="font-rubik disg-info">International Coaching Federation</span> -->
								</div> <!-- /.feedback-wrapper -->
							</div> <!-- /.clients-feedback-classic -->
						</div>
					</div>
				</div>
			</div> <!-- /.fancy-feature-three -->


			<!--
			=====================================================
				Fancy Text block Eight
			=====================================================
			-->
			<div class="fancy-text-block-eight pt-100 mt-100 md-pt-100">
				<div class="container">
					<div class="title-style-two text-center md-mb-10">
						<div class="row">
							<div class="col-xl-8 col-lg-9 col-md-10 m-auto" id="features">
								<h2 class="fs-50 fw-600">
									<span>Features</span>
								</h2>
							</div>
						</div>
					</div>

					<div class="block-style-six md-pb-70">
						<div class="row">
							<div class="col-lg-5" data-aos="fade-right" data-aos-duration="1200">
								<div class="text-details pt-35 md-pb-50 md-pt-30">
									<h3 class="title font-gilroy-bold fs-30 pb-15">Interactive coaching</h3>
									<ul class="checkmark">
										<li class="text-dark">Message your coaches live in-session</li>
										<li class="text-dark">Send one-on-one messages between sessions</li>
										<li class="text-dark">Celebrate milestones achieved</li>
									</ul>
									<h3 class="title font-gilroy-bold pt-40 fs-30 pb-15">A wide variety of coaching
										communities
									</h3>
									<ul>
										<li class="text-dark"><b>CAREER:</b> leadership, decrease burnout, EQ,
											accountability, mindset,
											and more.</li>
										<li class="text-dark"><b>WELLNESS:</b> self-care, self-esteem, eating well,
											healthy lifestyles, and
											more.</li>
										<li class="text-dark"><b>LIFE:</b> parenting, debt-relief, dating, divorce,
											relationships, motherhood,
											and more.</li>
										<li class="text-dark"><b>SOUL:</b> mindfulness, meditation, stress reduction,
											and more.</li>
									</ul>
								</div> <!-- /.text-details -->
							</div>

							<div class="col-lg-7 col-md-9 m-auto" data-aos="fade-left" data-aos-duration="1200">
								<div class="illustration-holder illustration-one">
									<img src="https://dktoyr513tjgs.cloudfront.net/v2/images/assets/Ingomu-app-features-(@ingomu-@coaching-app-features).png"
										alt="" class="ml-auto">
								</div>
							</div>
						</div>
					</div> <!-- /.block-style-six -->
				</div>
			</div> <!-- /.fancy-text-block-eight -->

									<!--
			=====================================================
				Footer
			=====================================================
			-->
			<div class="pb-100 md-pb-100 pt-100 mt-100 md-pt-100 br-25 sm-mt-0">
				<div class="bubble-one"></div>
				<div class="bubble-two"></div>
				<div class="bubble-three"></div>
				<div class="fancy-short-banner-two">
					<div class="container">
						<div class="content-wrapper">
							<div class="bg-wrapper d-lg-flex align-items-center justify-content-between bg-ingomublue br-25">
								<h2 class="font-gilroy-bold">See how Ingomu can transform your business.</h2>
								<a href="https://dktoyr513tjgs.cloudfront.net/files/about-ingomu-coaching-for-business-v3.pdf" target="_blank">Get our info guide</a>
								<div class="bubble-one"></div>
								<div class="bubble-two"></div>
								<div class="bubble-three"></div>
								<div class="bubble-four"></div>
								<div class="bubble-five"></div>
							</div> <!-- /.bg-wrapper -->
						</div> <!-- /.content-wrapper -->
					</div> <!-- /.container -->
				</div> <!-- /.fancy-short-banner-two -->
			</div>


						<!-- 
			=============================================
				Theme Hero Banner
			============================================== 
			-->
			<div class="hero-banner-two">
				<div class="container">
					<div class="row align-items-start justify-content-between">
						<div class="col-lg-5 order-lg-last">
							<div class="illustration-holder">
								<img src="https://dktoyr513tjgs.cloudfront.net/v2/images/assets/Ingomu-employee-coaching-tips-(@ingomu-testimonial).png" alt="" class="illustration_01 br-25">
								<img src="https://dktoyr513tjgs.cloudfront.net/v2/images/assets/Ingomu-testimonial-corporate-user-1(@Ingomu-@Ingomu-testimonial).png" alt="" class="shapes shape-one bs-img">
								<img src="https://dktoyr513tjgs.cloudfront.net/v2/images/assets/Ingomu-testimonial-corporate-user-2(@Ingomu-@Ingomu-testimonial).png" alt="" class="shapes shape-two bs-img">
								<img src="https://dktoyr513tjgs.cloudfront.net/v2/images/assets/Ingomu-testimonial-corporate-user-3(@Ingomu-@Ingomu-testimonial).png" alt="" class="shapes shape-three bs-img">
							</div>
						</div>

						<div class="col-xl-6 col-lg-7 order-lg-first">
							<div class="hero-text-wrapper md-mt-50">
								<h2 class="lh-1 fs-50 fw-600">
									Employee <span class="logo-swish"> Coaching</span> Tips for Busy HR Teams Directly to Your Inbox!
								</h2>
								<p class="sub-text">Step-by-step tips to build employee coaching systems that attract, develop, and retain talent.</p>
								<form v-on:submit.prevent="submit('newsletter')" data-vv-scope="newsletter">
									<input type="email" class="required email" v-model="newsletterForm.email" name="actionemail"
                                    :class="{ 'border-red': errors.has('newsletter.email') }"
                                    placeholder="Your Email Address" v-validate="'required|email'"
                                    id="actionemail" required="required" data-error="A valid email is required." />
									<button>Get Started</button>
								</form>
								<ul class="list-item d-flex mt-20">
									<li>No buzzwords and gimmicks, just strategy with practical advice that delivers results.</li>
									<!-- <li>Ticketing</li>
									<li>14 days free trial</li> -->
								</ul>
							</div> 
						</div>
					</div> <!-- /.row -->
				</div> <!-- /.container -->
			</div>
			 <!-- /.hero-banner-two -->


			<!--
			=====================================================
				Faq Classic
			=====================================================
			-->
			<div class="faq-classic with-bg pt-0 bg-white pb-0">
				<div class="container">
					<div class="title-style-four text-center mb-100 md-mb-70">
						<div class="row" id="faq">
							<div class="col-lg-7 m-auto">
								<h6 class="text-dark">FAQ’s</h6>
								<h2 class="fs-50 fw-600">
									<span>Questions & Answers</span>
								</h2>
							</div>
						</div>
					</div>

					<div class="row">
						<div class="col-lg-6">
							<!-- ================== FAQ Panel ================ -->
							<div id="accordion">
								<div class="card bg-lblue" v-for="(item, index) in faqGeneral" :key="index">
									<div class="card-header" :id="item.heading">
										<h5 class="mb-0">
											<button class="btn btn-link">
												{{ item.question }}
											</button>
										</h5>
									</div>
									<div :id="item.controls" class="collapse">
										<div class="card-body">
											<p class="text-dark fs-20" v-html="item.answer"></p>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div class="col-lg-6">
							<!-- ================== FAQ Panel ================ -->
							<div id="accordiontwo">
								<div class="card bg-lblue" v-for="(item, index) in faqGeneral1" :key="index">
									<div class="card-header" :id="item.heading">
										<h5 class="mb-0">
											<button class="btn btn-link">
												{{ item.question }}
											</button>
										</h5>
									</div>
									<div :id="item.controls" class="collapse">
										<div class="card-body">
											<p class="text-dark fs-20" v-html="item.answer"></p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div> <!-- /.faq-classic -->

			<!--
			=====================================================
				Footer Style Seven
			=====================================================
			-->
			<SiteFooter></SiteFooter>

			<!-- Modal Contact Form One -->
			<div class="modal fade modal-contact-popup-one" id="contactModal" tabindex="-1" role="dialog"
				aria-labelledby="contactModalTitle" aria-hidden="true">
				<ScheduleCall @clicked="closeModal"></ScheduleCall>
			</div>

			<!-- Scroll Top Button -->
			<button class="scroll-top">
				<i class="fa fa-angle-up" aria-hidden="true"></i>
			</button>
		</div>
	</div>
</template>

<script>
import postscribe from "postscribe";
import SiteHeader from "@/components/EnterpriseHeader";
import SiteFooter from "@/components/SiteFooter";
import ScheduleCall from "@/components/ScheduleCall";
import { mapActions, mapGetters } from "vuex";

const TIMEOUT = 1000;

export default {
	name: "business",
	metaInfo() {
		return {
			title: "Empower Your Business: Ingomu Coaching Solutions",
		}
	},
	components: {
		SiteHeader,
		SiteFooter,
		ScheduleCall
	},
	data() {
		return {
			newsletterForm: {
                email: ""
            },
			testimonialsList: [
				{
					img: "",
					name: "Joel F.",
					title: "Joined a much needed session on the app today. I am dealing with conflict at work due to the very different values and opinions on my team. It’s making work more than a bit difficult. During Carolyn’s session I gained great insights and effective strategies to overcome these challenges. Thanks and see you in the next session.<br><br>"
				},
				{
					img: "",
					name: "Lisa F.",
					title: "What I love about Ingomu is the coaching that is available 24/7 on the app.  I have worked every shift and every schedule, and this is the first time I have felt like quality support was at my fingertips no matter what time of the day it was.<br><br>"
				},
				{
					img: "",
					name: "Aiden",
					title: "I've been going through a tough time at work and it's been spilling over into my home life. Started using the app a while back and I've found so much support in the communities. They've really helped me come up with strategies to tackle the challenges, both at work and at home. They’ve boosted my confidence and helped me improve my relationships with my colleagues and family. I'm thankful for the impact this app has had on my life and career.<br><br>"
				},
				{
					img: "",
					name: "Ellie S.",
					title: "I can't believe how energized I still feel after being part of Coach Sullivan's coaching session! Learning about turning mediocre teams into high performing ones was an absolute game-changer. The way she coached wasn't only packed with valuable insights, but the whole experience was like a burst of positivity and enthusiasm. A friend recommended I get the app, and this was one of the first sessions I joined. Seriously, why didn't I discover this earlier in my career?<br><br>"
				},
				{
					img: "",
					name: "MJ",
					title: "The Ingomu app is amazing. As a nurse, I seldom have time to think about myself. I have learned a lot about setting boundaries and protecting my time, as well as building a more effective team at work.  Using the app, I also have been able to put more focus on self-care.<br><br>"
				},
				{
					img: "",
					name: "Anastasia S.",
					title: "Where else can you get affordable access to unlimited coaching when you need it? I've been able to control my anxiety by joining live sessions. I'm less stressed and more focused! Thanks.<br><br>"
				},
				{
					img: "",
					name: "C. J.",
					title: "I love being able to join various coaching communities and engage with the coach in each one. The flexibility of joining live or watching on-demand is perfect.<br><br>"
				},
				{
					img: "",
					name: "Brad B.",
					title: "In partnership with Ingomu, we will achieve our goals for a thriving culture that promotes well-being.<br><br>"
				},
				{
					img: "",
					name: "Darren M.",
					title: "I’m up for a promotion and my manager suggested I check out the Ingomu app in preparation. I'm amazed by the impressive selection of coaches and the high-quality content and the impact it's already having on my personal and professional growth.<br><br>"
				}
			],
			faqGeneral: [
				{
					question: "Are Ingomu coaches vetted?",
					answer:
						"Ingomu coaches go through a stringent vetting process. We require 10-plus years of verifiable coaching experience, and coaches must have the necessary certifications in their area of coaching.",
					id: "headingOne",
					target: "#collapseOne",
					controls: "collapseOne",
					show: "show",
				},
				{
					question: "How much does Ingomu cost?",
					answer:
						"Ingomu provides an unparalleled value in personal coaching. The investment is less than $1 per day per employee. Pricing can vary depending on the number of licenses invested in.",
					id: "headingTwo",
					target: "#collapseTwo",
					controls: "collapseTwo",
					show: "",
				},
				{
					question: "Is there an access limit to coaches for my employees?",
					answer:
						'There is not. All users have access to all coaches available on the app for scheduled virtual group sessions, and in case they missed a session, or want to see it again, have on-demand access to hundreds of recorded coaching sessions. There is also unlimited engagement with the coaches between sessions, and one-on-one messaging with coaches.',
					id: "headingThree",
					target: "#collapseThree",
					controls: "collapseThree",
					show: "",
				},
				{
					question: "As my employees participate in coaching, is their information private?",
					answer:
						"Yes, personal information is private. Only your employee’s first name is shared with the coaches.",
					id: "headingFour",
					target: "#collapseFour",
					controls: "collapseFour",
					show: "",
				}
			],
			faqGeneral1: [
				{
					question: "How do I know if Ingomu works for my employees?",
					answer:
						"Monthly reports are distributed highlighting key areas users are working on, and the improvement or efficacy in these areas. Data shared is platform specific and anonymous. No individual user data is ever shared. This is included with the client's subscription. Custom reporting can be developed.",
					id: "headingFive",
					target: "#collapseFive",
					controls: "collapseFive",
					show: "",
				},
				{
					question: "How long does it take to implement Ingomu?",
					answer:
						'Ingomu can be implemented in as little as a day. The app is easy to use and there are no integrations with other software or tools needed.',
					id: "headingSix",
					target: "#collapseSix",
					controls: "collapseSix",
					show: "",
				},
				{
					question: "What does support look like, what is your tech support?",
					answer:
						"The Ingomu app is easy to use. There are no integrations with other software or tools needed. Technical questions from clients and learners can be directed to the success coach.<br><br>A help menu and weekly drop-in sessions are available for support as well.<br><br>The Ingomu landing page for the company or institution will be a help resource as well.",
					id: "headingSeven",
					target: "#collapseSeven",
					controls: "collapseSeven",
					show: "",
				},
				{
					question: "I would like to speak with someone.",
					answer:
						"Please <a href=\"#\" data-toggle=\"modal\" data-target=\"#contactModal\" class=\"demo-button\">click here</a> to send us a message and a team member will get back to you.",
					id: "headingEight",
					target: "#collapseEight",
					controls: "collapseEight",
					show: "",
				}
			],
		}
	},
	mounted() {
		if (this.$route.hash) {
			setTimeout(() => this.scrollTo(this.$route.hash), TIMEOUT);
		}
		postscribe(
			"#business",
			`<script src="https://dktoyr513tjgs.cloudfront.net/v2/vendor/mega-menu/assets/js/custom.js"><\/script>`
		);
		postscribe(
			"#business",
			`<script src="https://dktoyr513tjgs.cloudfront.net/v2/js/theme.js?v=3"><\/script>`
		);
	},
	methods: {
		...mapActions(["newsletterBusiness"]),
		submit(scope) {
            var _this = this;
            this.$validator.validateAll(scope).then(function (res) {
                if (res) {
                    _this
                        .newsletterBusiness({
                            email: _this.newsletterForm.email
                        })
                        .then(() => {
                            _this.newsletterForm.email = "";
                            _this.$validator.reset();
                            _this.$fire({
                                title: "Success",
                                text: "Thank you for subscribing. Welcome to our community! Look for your first Employee Coaching Tips for Busy HR Teams shortly.",
                                type: "success",
                            });
                        })
                        .catch((error) => {
                            console.log(error);
                            _this.$fire({
                                title: "Error",
                                text: "Sorry, there seems to be a problem.",
                                type: "error",
                            });
                        });
                }
            });
        },
		closeModal(value) {
			if (value) {
				this.$fire({
					title: "Success",
					text: "Thank you! Our team has received your message. Keep an eye on your inbox; we'll be in touch shortly.",
					type: "success",
				});
			}
		},
		scrollTo(hashtag) {
			setTimeout(() => {
				const element = document.getElementById(hashtag.slice(1)); // Your target element
				const headerOffset = 150;
				const elementPosition = element.getBoundingClientRect().top;
				const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

				window.scrollTo({
					top: offsetPosition,
					behavior: "smooth",
				});
			}, TIMEOUT)
		}
	}
}
</script>
<style scoped>
.w997 {
	width: 997px;
}

.app-screen-preview-one {
	margin-top: 70px !important;
}

.block-style-six:nth-child(even):before {
	background: none;
}

.mb-50 {
	margin-bottom: 50px;
}

.mt-150 {
	margin-top: 150px;
}

.fs-20 {
	font-size: 20px !important;
}

.fs-45 {
	font-size: 45px;
}

.pb-0 {
	padding-bottom: 0;
}

.bg-lblue {
	background: #F7FAFF;
}

.bg-white {
	background: #fff;
}

.b-neg50 {
	bottom: -50px
}

.client-feedback-slider-two .bg-wrapper:before {
	bottom: 5px;
}

.fs-18 {
	font-size: 18px !important;
}

.fs-16 {
	font-size: 16px !important;
}

.pt-40 {
	padding-top: 40px;
}

.fs-30 {
	font-size: 30px;
}

.pb-15 {
	padding-bottom: 15px !important;
}

.pt-100 {
	padding-top: 100px !important
}

.pb-150 {
	padding-bottom: 150px
}

.p40 {
	padding: 40px !important;
}

.counter-box-one p {
	font-size: 15px !important;
}

.pt-150 {
	padding-top: 150px
}

.fs30 {
	font-size: 30px !important;
}

.counter-box-one.color-three,
.counter-info-classic .bubble-two,
.counter-info-classic .bubble-four,
.counter-info-classic .bubble-five,
.counter-info-classic .bubble-seven {
	background: #3b5998;
}

.counter-box-one.color-two,
.counter-info-classic .bubble-one,
.counter-info-classic .bubble-three,
.counter-info-classic .bubble-six {
	background: #46c346;
}

.mb40 {
	margin-bottom: 40px !important;
}

.title-style-four span img {
	bottom: -50px !important
}

.fw600 {
	font-weight: 600 !important;
}

.clients-feedback-classic .feedback-wrapper:before {
	content: none !important;
}

.fancy-short-banner-two a:hover {background: #46c346; color: #fff;}

@media (max-width: 1370px) {
.clients-feedback-classic {
	padding-left: 40px;
}
}

.letstalkbutton {
	width: 210px;
  font-size: 17px;
  line-height: 56px;
  border: 2px solid #000;
  text-align: center;
  border-radius: 8px;
  font-weight: 500;
  color: #000;
  position: relative;
  transition: all 0.3s ease-in-out;
}

.letstalkbutton a:link, .letstalkbutton a:visited, .letstalkbutton a:hover {
	color: #000;
}

.br-25 {
	border-radius: 25px;
}
	.sub-text {
		font-size: 24px !important;
		font-weight: 400 !important;
		line-height: 1.5 !important;
		color: #333 !important;
	}
	.hero-banner-two .hero-text-wrapper form button {
		background: #3b4998 !important;
	}
	.hero-banner-two .hero-text-wrapper form button:hover {background: #46c346 !important;}
	.lh-13 {
		line-height: 1.3 !important;
	}
	.hero-banner-two .illustration-holder .shape-one {
		left: 3% !important;
	}
	.client-feedback-slider-one .clientSliderOne p {
		font-size: 20px;
	}
	.logo-swish {
		display: inline-block;
		position: relative;
	}
	.swish-image {
		position: absolute;
		top: 87%;
	}
	@media (max-width: 1370px) {
		.md-mr-auto {
			margin-right: auto !important;
		}
	}
	@media (max-width: 1370px) {
.hero-banner-two .container {
	padding: 0 25px;
}
}

.fs-50 {
	font-size: 50px !important;
}

.fw-600 {
	font-weight: 600 !important;
}

.title-style-five h2 span:before {
	background: none !important;
}

.bluebutton {
	background: #3b5998;
	border: none;
}

.bluebutton:hover {
	background-color: #46c346;
}

.color-white {
	color: #fff !important;
}

.mt-38 {
	margin-top: 38px !important;
}

.client-feedback-slider-two .bg-wrapper .name {
	font-weight: bold;
}

@media (max-width: 767px) {
	.sm-mt-0 {
		margin-top: 0 !important;
	}

	.sm-pt-0 {
		padding-top: 0 !important
	}
}

.client-feedback-slider-two .slick-dots li.slick-active button {background: #3b5998 !important;}
</style>